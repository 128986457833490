import React from 'react';
import PropTypes from 'prop-types';
import { COMMON_USER_VISIBILITY, ROLES } from 'utils/constants';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { IoCloseOutline } from 'react-icons/io5';
import SideBarLink from './SideBarLink';
import ChangeCompanyLink from './ChangeCompanyLink';

const SideBar = ({
  isCollapsed,
  currentUserRole,
  currentUserCompany,
  setIsCollapsed,
  visibility,
}) => {
  const isXactAdmin = currentUserRole === ROLES.xactAdmin;
  const isCompanyAdmin =
    currentUserRole === ROLES.companyAdmin &&
    !currentUserCompany?.isCompanyAdminPartner;
  const isCompanyAdminPartner =
    currentUserRole === ROLES.companyAdmin &&
    currentUserCompany?.isCompanyAdminPartner;
  const isZoneManager = currentUserRole === ROLES.zoneManager;
  const isCommonUser = currentUserRole === ROLES.commonUser;
  const renderXactAdminActions = () => (
    <>
      <SideBarLink linkName='zones' isCollapsed={isCollapsed} />
      <SideBarLink linkName='monitors' isCollapsed={isCollapsed} />
      <SideBarLink linkName='companies' isCollapsed={isCollapsed} />
      <SideBarLink linkName='users' isCollapsed={isCollapsed} />
      <SideBarLink linkName='invoices' isCollapsed={isCollapsed} />
      <SideBarLink linkName='price_plans' isCollapsed={isCollapsed} />
    </>
  );

  const renderCompanyAdminActions = () => (
    <>
      <SideBarLink linkName='zones' isCollapsed={isCollapsed} />
      <SideBarLink linkName='monitors' isCollapsed={isCollapsed} />
      <SideBarLink
        linkName='company'
        id={currentUserCompany?.companyId}
        isCollapsed={isCollapsed}
      />
      <SideBarLink linkName='users' isCollapsed={isCollapsed} />
      {!currentUserCompany?.isSubTenant && (
        <SideBarLink linkName='invoices' isCollapsed={isCollapsed} />
      )}
    </>
  );

  const renderCompanyAdminPartnerActions = () => (
    <>
      <SideBarLink linkName='zones' isCollapsed={isCollapsed} />
      <SideBarLink linkName='monitors' isCollapsed={isCollapsed} />
      <SideBarLink linkName='companies' isCollapsed={isCollapsed} />
      <SideBarLink linkName='users' isCollapsed={isCollapsed} />
      {/* Section hidden temporarily */}
      {/* <SideBarLink linkName='invoices' isCollapsed={isCollapsed} /> */}
    </>
  );

  const renderZoneManagerActions = () => (
    <>
      <SideBarLink linkName='zones' isCollapsed={isCollapsed} />
      <SideBarLink linkName='monitors' isCollapsed={isCollapsed} />
    </>
  );

  const renderCommonUserActions = () => {
    if (
      visibility === COMMON_USER_VISIBILITY.ZONES ||
      visibility === COMMON_USER_VISIBILITY.COMPANY
    )
      return (
        <>
          <SideBarLink linkName='zones' isCollapsed={isCollapsed} />
          <SideBarLink linkName='monitors' isCollapsed={isCollapsed} />
        </>
      );

    return <SideBarLink linkName='monitors' isCollapsed={isCollapsed} />;
  };

  return (
    <Box
      bg='alpha.500'
      w={{
        xs: !isCollapsed ? '260px' : '0px',
        md: !isCollapsed ? '260px' : '72px',
        lg: '260px',
      }}
      minW={{
        xs: !isCollapsed ? '260px' : '0px',
        md: !isCollapsed ? '260px' : '72px',
        lg: '260px',
      }}
      transition='width, left, right, 0.3s'
    >
      <Box minHeight='72px' />
      <Box display={{ xs: !isCollapsed ? 'block' : 'none', md: 'block' }}>
        <Flex alignItems='center'>
          <ChangeCompanyLink
            isCollapsed={isCollapsed}
            isXactAdmin={isXactAdmin}
            isCompanyAdminPartner={isCompanyAdminPartner}
          />
          <Icon
            as={IoCloseOutline}
            onClick={() => setIsCollapsed(true)}
            color='white'
            boxSize='5'
            ml='auto'
            mr='6'
            mb='20px'
            display={{ xs: !isCollapsed ? 'block' : 'none', lg: 'none' }}
          />
        </Flex>
        {isXactAdmin && renderXactAdminActions()}
        {isCompanyAdmin && renderCompanyAdminActions()}
        {isZoneManager && renderZoneManagerActions()}
        {isCommonUser && renderCommonUserActions()}
        {isCompanyAdminPartner && renderCompanyAdminPartnerActions()}
      </Box>
    </Box>
  );
};

SideBar.propTypes = {
  isCollapsed: PropTypes.bool,
  currentUserRole: PropTypes.string,
  currentUserCompany: PropTypes.shape({
    companyId: PropTypes.number,
    isSubTenant: PropTypes.bool,
    isCompanyAdminPartner: PropTypes.bool,
  }),
  setIsCollapsed: PropTypes.func,
  visibility: PropTypes.string,
};

export default SideBar;
